<template>
  <el-drawer
    title="账号列表"
    size="1000px"
    append-to-body
    :visible.sync="show"
    :before-close="handleClose"
  >
    <div class="baidu-account-container">
      <dy-table
        ref="AccTable"
        height="700px"
        refName="subTable"
        :page="page"
        :total="total"
        :loading="loading"
        :rowList="rowList"
        :dataList="dataList"
        :pageSize="pageSize"
        :isUseCheckbox="true"
        :showPagination="true"
        @pageChange="pageChange"
        @selection-change="handleSelectionChange"
        @handleRowClick="handleRowClick"
      >
        <template #filter>
          <el-select
            class="margin-right-ten"
            v-model="accountChannelId"
            placeholder="请选择公众号"
            style="width: 140px"
            @change="pageChange(1)"
            filterable
            clearable
          >
            <el-option
              v-for="item in channelList"
              :key="item.id"
              :label="item.channelName"
              :value="item.id"
            >
              <span style="float: left">{{ item.channelName }}</span>
              <span style="float: right; margin-left: 15px; font-size: 13px"
                >{{ item.platformStr }} - {{ item.uname }}</span
              >
            </el-option>
          </el-select>
          <el-select
            v-model="booksId"
            filterable
            remote
            clearable
            style="width: 150px"
            reserve-keyword
            placeholder="书籍名称"
            :remote-method="getBookList"
            @change="pageChange(1)"
          >
            <el-option
              v-for="item in bookList"
              :key="item.id"
              :label="item.bookName"
              :value="item.id"
            >
            </el-option>
          </el-select>
          <el-input
            v-model="search"
            style="width: 200px; margin: 0 10px"
            clearable
            placeholder="请输入账号名称!"
          >
            <el-button
              slot="append"
              @click=";(page = 1), initData()"
              icon="el-icon-search"
            ></el-button>
          </el-input>
          <el-input
            v-model="remark"
            style="width: 200px"
            clearable
            placeholder="请输入备注!"
          >
            <el-button
              slot="append"
              @click=";(page = 1), initData()"
              icon="el-icon-search"
            ></el-button>
          </el-input>
          <c-button
            style="margin-left: 10px"
            type="primary"
            @click="
              (cb) => {
                batchEditRemark(cb)
              }
            "
            :disabled="selection.length === 0"
            >批量修改备注</c-button
          >
        </template>
        <template #operation="{ row }">
          <el-button
            class="button-small"
            type="primary"
            @click="editRemark(row)"
            >修改备注</el-button
          >
        </template>
      </dy-table>
      <div class="button-list">
        <el-button type="primary" @click="handleConfirm"> 确定 </el-button>
        <el-button @click="handleCancel">取消</el-button>
      </div>
    </div>
  </el-drawer>
</template>

<script>
import { getAllBaiduAccountList, editBaiduAccountRemark } from '@/api/baidu'
import { messagePlatformList } from '@/assets/js/options'
import { getBookList } from '@/api/account'
import { mapGetters } from 'vuex'
export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    baiduAccountList: Array,
    // ! 清除selection的标识
    isSubmit: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      page: 1,
      total: 0,
      remark: '',
      search: '',
      pageSize: 15,
      bookList: [],
      dataList: [],
      booksId: null,
      selection: [],
      loading: false,
      copyAccountList: [],
      isRenderAuto: true, // ! 是否是回显自动勾选表格行
      accountChannelId: null,
      rowList: [
        {
          label: '账号名称',
          prop: 'targetName',
        },
        {
          label: '书籍名称',
          prop: 'bookName',
        },
        {
          label: '公众号',
          prop: 'channelName',
        },
        {
          label: '备注',
          prop: 'remark',
        },
        {
          label: '操作',
          action: 'operation',
        },
      ],
    }
  },
  computed: {
    channelList() {
      const { officialList } = this
      //  messagePlatformList.includes(item.platform) &&
      return officialList.filter((item) => item.status === 1)
    },
    ...mapGetters(['officialList', 'userInfo']),
  },
  watch: {
    show: {
      handler(newV) {
        if (newV) {
          this.copyAccountList = JSON.parse(
            JSON.stringify(this.baiduAccountList)
          )
          this.isRenderAuto = true
          this.initData()
          this.getBookList()
          this.initEvent()
        } else {
          document.removeEventListener('keydown', this.handleKeyDown, false)
        }
      },
    },
    isSubmit: {
      handler(newV) {
        if (newV) {
          this.selection = []
        }
      },
    },
  },
  methods: {
    initData() {
      this.isRenderAuto = true
      this.loading = true
      getAllBaiduAccountList({
        page: this.page,
        pageSize: this.pageSize,
        targetName: this.search,
        accountChannelId: this.accountChannelId,
        booksId: this.booksId,
        remark: this.remark,
      })
        .then((res) => {
          res.list.forEach((item) => {
            item.checked = false
          })
          this.dataList = res.list
          this.total = res.total
        })
        .finally(async () => {
          this.loading = false
          this.$nextTick(async () => {
            // ! 第一步 通过 传入的baiduAccountList 来回显勾选状态
            await this.echoChecked()
            await this.checkAccountList()
            // ! 第二部 还需要通过selection来回显勾选状态
            await this.checkSelectionList()
            this.isRenderAuto = false
          })
        })
    },
    getBookList(bookName) {
      getBookList({
        page: 1,
        pageSize: 50,
        bookName,
      }).then((res) => {
        this.bookList = res.list
      })
    },
    initEvent() {
      document.addEventListener('keydown', this.handleKeyDown, false)
    },
    handleKeyDown(e) {
      const { keyCode } = e
      if (keyCode === 13) {
        this.initData()
      }
    },
    checkSelectionList() {
      return new Promise((resolve) => {
        if (!this.selection.length) return resolve()
        this.dataList.forEach((account) => {
          const targetItem = this.selection.find(
            (item) => item.childId === account.childId
          )

          if (!targetItem) {
            this.$refs.AccTable.$refs.subTable.toggleRowSelection(
              account,
              false
            )
            account.checked = false
          } else {
            this.$refs.AccTable.$refs.subTable.toggleRowSelection(account, true)
            account.checked = true
          }
        })
        this.$nextTick(() => {
          resolve()
        })
      })
    },
    checkAccountList() {
      return new Promise((resolve) => {
        this.copyAccountList.forEach((account) => {
          const targetItem = this.dataList.find(
            (item) => item.childId === account.childId
          )
          const targetIndex = this.dataList.findIndex(
            (item) => item.childId === account.childId
          )
          if (targetItem) {
            this.dataList[targetIndex].checked = true
          }
        })
        this.$nextTick(() => {
          resolve()
        })
      })
    },
    echoChecked() {
      return new Promise((resolve) => {
        if (this.copyAccountList.length > 0) {
          this.copyAccountList.forEach((account) => {
            const targetItem = this.dataList.find(
              (item) => item.childId === account.childId
            )
            if (targetItem) {
              this.$refs.AccTable.$refs.subTable.toggleRowSelection(
                targetItem,
                true
              )
            }
          })
        }
        this.$nextTick(() => {
          resolve()
        })
      })
    },
    pageChange(page) {
      this.page = page
      this.initData()
    },
    handleSelectionChange(select) {
      this.$nextTick(() => {
        if (this.isRenderAuto) return
        this.dataList.forEach((account) => {
          account.checked = !!select.find(
            (item) => item.childId === account.childId
          )
          // ! 如果被选中，则 this.selection push 否则pop
          const targetIndex = this.selection.findIndex(
            (item) => item.childId === account.childId
          )
          if (account.checked) {
            if (targetIndex === -1) {
              this.selection = [...this.selection, account]
            }
          } else {
            if (targetIndex > -1) {
              this.selection.splice(targetIndex, 1)
            }
          }
        })
      })
    },
    handleClose() {
      this.$emit('close')
    },
    editRemark(row) {
      this.$prompt('修改备注', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        inputValue: `${row.remark ? row.remark : ''}`,
      })
        .then(({ value }) => {
          if (!value.trim()) {
            return this.$message.warning('备注不能为空！')
          }
          editBaiduAccountRemark(row.childId, {
            remark: value,
          }).then(() => {
            this.$message.success('修改成功!')
            this.initData()
          })
        })
        .catch(() => {})
    },
    batchEditRemark(cb) {
      const ids = this.selection.map((item) => item.childId).join(',')
      this.$prompt('修改备注', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
      })
        .then(({ value }) => {
          if (!value.trim()) {
            return this.$message.warning('备注不能为空！')
          }
          editBaiduAccountRemark(ids, {
            remark: value,
          })
            .then(() => {
              this.$message.success('修改成功!')
              this.selection = []
              this.initData()
            })
            .finally(() => {
              cb()
            })
        })
        .catch(() => {})
    },
    handleRowClick(row) {
      this.$refs.AccTable.$refs.subTable.toggleRowSelection(row)
    },
    handleConfirm() {
      this.$emit('chooseSuccess', this.selection)
      this.resetData()
      this.handleClose()
    },
    handleCancel() {
      this.handleClose()
    },
    resetData() {
      this.page = 1
      this.search = ''
      this.accountChannelId = null
      this.booksId = null
      this.remark = null
    },
  },
  components: {},
}
</script>
<style lang='scss' scoped>
.baidu-account-container {
  position: relative;
  .button-list {
    position: absolute;
    bottom: 0;
  }
}
</style>
