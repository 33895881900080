<template>
  <el-dialog
    title="预览合集"
    append-to-body
    width="1350px"
    top="20px"
    :visible.sync="show"
    :before-close="cancel"
  >
    <div class="preview-container">
      <div
        class="preview-item flex-row-start-start"
        v-for="(previewItem, index) in copyData"
        :key="index"
      >
        <!-- <div style="margin-right: 20px; flex-grow: 1">
          <template v-if="index === 0">
            <span style="font-weight: bold">一对一模式：{{previewItem.length}}个</span>
          </template>
          <template v-else-if="index === 1">
            <span style="font-weight: bold">多对多模式：</span>
          </template>
          <div class="preview-item-detail flex-row-start-start-wrap">
            <div
              class="p-item"
              v-for="(detail, index2) in previewItem"
              :key="index2"
            >
              <div class="image">
                <img :src="detail.image.src" alt="" />
              </div>
              <div class="copywriting line-overflow">
                <span style='margin-left:5px'>创意标题：{{ detail.text }}</span>
              </div>
              <i
                class="el-icon-delete-solid del-icon"
                style="cursor: pointer"
                @click="delItem(previewItem, index2, index)"
              >
              </i>
            </div>
          </div>
        </div> -->

        <div style="margin-right: 20px;flex-grow:1"> <span style='font-weight:bold'>账号名称</span> ：{{ previewItem[0] }}</div>
        <div class="preview-item-detail flex-row-start-start-wrap">
          <div
            class="p-item"
            v-for="(detail, index2) in previewItem[1]"
            :key="index2"
          >
            <div class="image">
              <img :src="detail.image.src" alt="" />
            </div>
            <div class="copywriting line-overflow">
              创意标题：{{ detail.creative_title }}
            </div>
            <i
              class="el-icon-delete-solid del-icon"
              style="cursor: pointer"
              @click="delItem(previewItem[1], index2)"
            ></i>
          </div>
        </div>
      </div>
    </div>
    <div
      style="text-align: left; padding-top: 10px; border-top: 1px solid #f5f5f5"
    >
      <el-button type="primary" @click="handleSave"> 确定</el-button>
      <el-button @click="cancel">取消</el-button>
    </div>
  </el-dialog>
</template>

<script>
export default {
  props: {
    show: Boolean,
    data: Array
  },
  data () {
    return {
      copyData: {}
    }
  },
  watch: {
    show: {
      handler (newV) {
        if (newV) {
          this.renderData()
        }
      }
    }
  },
  methods: {
    renderData () {
      this.copyData = JSON.parse(JSON.stringify(this.data))
    },
    handleClose () {
      this.$emit('close')
    },
    delItem (val, index) {
      if(val.length === 1){
        return this.$message.warning('至少保留一个计划!')
      }
      val.splice(index,1)
      // val.splice(subIndex, 1)
    },
    cancel () {
      this.$emit('saveData',null)
      this.$emit('close')
    },
    handleSave () {
      this.$emit('saveData', this.copyData)
      this.$emit('close')
    }
  },
  components: {}
}
</script>
<style lang='scss' scoped>
.preview-container {
  max-height: 700px;
  overflow: auto;
  .preview-item {
    margin-bottom: 30px;
    .preview-item-detail {
      position: relative;
      margin-top:10px;
      width:80%;
      .p-item {
        margin: 0 10px 10px 0;
        position: relative;
        width: 200px;
        .image {
          width: 200px;
          max-height: 150px;
          background: #ccc;
          border-radius: 4px;
          overflow: hidden;
          display: flex;
          justify-content: center;
          align-items: center;

          img {
            width: 100%;
            object-fit: contain;
          }
        }
        .copywriting {
          height: 30px;
          line-height: 30px;
          font-size: 13px;
          background:#f5f5f5;
          padding-left:5px;

        }
        .del-icon {
          position: absolute;
          left: 90%;
          top: 5%;
          font-size: 18px;
          color: red;
          display: none;
        }
        &:hover {
          .del-icon {
            display: block;
          }
        }
      }
    }
  }
}
</style>
