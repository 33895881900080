<template>
  <div class="countable-textarea">
    <div class="flex-row-start-start" ref='container'>
      <ul class="line-count">
        <li v-for="n in count" :key="n">{{ n }}</li>
      </ul>
      <el-input
        class="custom-textarea"
        type="textarea"
        style="line-height:30px"
        v-model="content"
        @keyup.enter.native="enterEvent"
        :autosize="{ minRows: 7 }"
      ></el-input>
    </div>
    <div class='custom-tips'>
        {{tips}}
    </div>
  </div>
</template>

<script>
import { calculateCharacter } from '@/assets/js/utils'
export default {
  props: {
    tips: {
      type: String,
      default: '点击回车填写下一条，每条标题最多不超过60字符，部分流量上超出以“……”显示。'
    },
    docList: {
      type: Array,
      default: () => { [] }
    },
    maxPerLineLength: {
      type: [Number, String],
      default: 0

    }
  },
  data () {
    return {
      content: '',
      count: 1
    }
  },
  // mounted () {
  //   if (this.docList.length > 0) {
  //     this.content = this.docList.join('\n')
  //   }
  // },
  watch: {
    content: {
      handler (newV) {
        this.changeContent()
        if (this.maxPerLineLength !== 0) {
          this.checkLineLength()
        }
        this.$emit('exposeValue', this.content.split('\n').filter(val => !!val))
      }
    },
    docList: {
      handler (newV) {
        if (newV.length > 0) {
          this.content = this.docList.join('\n')
        }
      }
    }
  },
  methods: {
    changeContent () {
      this.count = this.content.split('\n').length
    },
    checkLineLength () {
      const arr = this.content.split('\n').filter(val => !!val)
      const perLineLengthArr = arr.map(item => calculateCharacter(item))
      if (perLineLengthArr.some(line => line > +this.maxPerLineLength)) {
        this.$refs.container.classList.add('error-tips-border')
      } else {
        this.$refs.container.classList.remove('error-tips-border')
      }
    },
    enterEvent (e) {
      this.count = this.content.split('\n').length
    }
  },
  components: {}
}
</script>
<style lang='scss' scoped>
/deep/ .el-textarea__inner{
      line-height:30px;
      padding:0 15px;
}
.countable-textarea {
  > div:nth-of-type(1) {
    border: 1px solid #d8d8d8;
    border-radius: 4px;
    height:212px;
    overflow-y:auto;
    overflow-x:hidden;
    .line-count {
      width: 30px;
      background: #eee;
      min-height: 210px;
      li {
        line-height: 30px;
        display: block;
        text-align: right;
        width: 20px;
        font-size:14px;
        color:#666;
      }
    }
    .custom-textarea {
      margin-top: -1px;
      margin-bottom: -1px;
      margin-right: -1px;
    }
  }
  .custom-tips{
    margin-top:5px;
    font-size:14px;
    color:#b8b8b8;
  }
  .error-tips-border{
    box-shadow: 0 0 0 2px #fd5d5a;
  }
}
</style>
