<template>
  <div class="add-template">
    <template v-if="!isSave">
      <div class="title">
        <i
          class="el-icon-back margin-right-ten"
          style="cursor: pointer; font-weight: bold; color: #ccc"
          @click="handleBack"
        ></i>
        <span>创建百度计划</span>
      </div>
      <div class="baidu-container">
        <div class="per-part">
          <h3>计划设置</h3>
          <div class="part">
            <span>预计消耗方式</span>
            <el-radio-group v-model="params.consumption">
              <el-radio-button label="标准投放"></el-radio-button>
              <el-radio-button label="均衡投放"></el-radio-button>
              <el-radio-button label="推广时段"></el-radio-button>
            </el-radio-group>
          </div>
          <div class="part">
            <span style="align-self: flex-start; margin-top: 10px">预算</span>
            <div>
              <el-radio-group v-model="budType">
                <el-radio-button label="不限"></el-radio-button>
                <el-radio-button label="自定义"></el-radio-button>
              </el-radio-group>
              <div style="margin-top: 10px" v-if="budType === '自定义'">
                <c-input
                  :onlyNum="true"
                  v-model="params.bud_get"
                  :min="0"
                  style="width: 123px"
                ></c-input>
                <span
                  style="margin-left: 5px; font-size: 14px; text-align: left"
                  >元</span
                >
              </div>
            </div>
          </div>
          <div class="part">
            <span style="align-self: flex-start; margin-top: 10px"
              >推广日期</span
            >
            <div>
              <el-radio-group v-model="params.promotionDate.type">
                <el-radio-button label="不限"></el-radio-button>
                <el-radio-button label="自定义"></el-radio-button>
              </el-radio-group>
              <div class="date-picker" style="margin-top: 10px">
                <el-date-picker
                  v-if="params.promotionDate.type === '自定义'"
                  v-model="params.promotionDate.value"
                  type="daterange"
                  value-format="yyyy-MM-dd"
                  range-separator="至"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                ></el-date-picker>
              </div>
            </div>
          </div>
          <div class="part">
            <span>推广时段</span>
            <el-radio-group v-model="params.promotionPeriod">
              <el-radio-button label="不限"></el-radio-button>
              <!-- <el-radio-button label="自定义"></el-radio-button> -->
            </el-radio-group>
          </div>
          <div class="part">
            <span class="required">计划名称</span>
            <el-input
              v-model="params.campaign_feed_name"
              placeholder="计划名称"
              style="width: 400px"
              show-word-limit
              :maxlength="90"
            ></el-input>
          </div>
        </div>
        <div class="per-part">
          <h3>流量选择</h3>
          <div class="part">
            <span>投放范围</span>
            <el-radio-group v-model="params.f_types">
              <el-radio-button label="百度信息流"></el-radio-button>
            </el-radio-group>
          </div>
        </div>
        <div class="per-part">
          <h3>定向选择</h3>
          <div class="part">
            <span style="align-self: flex-start; margin-top: 10px">地域</span>
            <div>
              <el-radio-group v-model="regionType">
                <el-radio-button label="不限"></el-radio-button>
                <el-radio-button label="自定义"></el-radio-button>
              </el-radio-group>
              <div class="region-container" v-if="regionType === '自定义'">
                <el-tree
                  :data="regionData"
                  ref="regionTree"
                  show-checkbox
                  node-key="id"
                  default-expand-all
                  @check="handleCheckChange"
                ></el-tree>
              </div>
            </div>
          </div>
          <div class="part">
            <span style="align-self: flex-start; margin-top: 10px">年龄</span>
            <div>
              <el-radio-group v-model="params.age.text">
                <el-radio-button label="不限"></el-radio-button>
                <el-radio-button label="自定义"></el-radio-button>
              </el-radio-group>
              <div
                class="age-range"
                style="margin-top: 10px"
                v-if="params.age.text === '自定义'"
              >
                <c-input
                  style="width: 100px"
                  v-model="params.age.startRange"
                  placeholder="最小18"
                  :isInter="true"
                  :onlyNum="true"
                  :max="56"
                  :min="18"
                ></c-input
                >-
                <c-input
                  style="width: 100px"
                  v-model="params.age.endRange"
                  placeholder="最大56"
                  :onlyNum="true"
                  :isInter="true"
                  :max="56"
                  :min="
                    +params.age.startRange + 9 >= 56
                      ? 56
                      : +params.age.startRange + 9
                  "
                ></c-input>
                <span style="color: #fd5d5a; font-size: 12px; margin-left: 10px"
                  >(最大年龄至少要比最小年龄大9岁，如果最小年龄+9>=56,则最大年龄以56为准，请仔细核对所填内容！)</span
                >
              </div>
            </div>
          </div>
          <div class="part">
            <span>性别</span>
            <el-radio-group v-model="params.sex">
              <el-radio-button label="男"></el-radio-button>
              <el-radio-button label="女"></el-radio-button>
            </el-radio-group>
          </div>
          <div class="part">
            <span>人生阶段</span>
            <el-radio-group v-model="params.life_stage">
              <el-radio-button label="不限"></el-radio-button>
            </el-radio-group>
          </div>
          <div class="part">
            <span>学历</span>
            <el-radio-group v-model="params.education">
              <el-radio-button label="不限"></el-radio-button>
            </el-radio-group>
          </div>
          <div class="part">
            <span>兴趣</span>
            <el-radio-group v-model="params.new_interests">
              <el-radio-button label="不限"></el-radio-button>
            </el-radio-group>
          </div>
          <div class="part">
            <span>意图词</span>
            <el-radio-group v-model="params.key_words">
              <el-radio-button label="不限"></el-radio-button>
            </el-radio-group>
          </div>
          <div class="part">
            <span>人群包</span>
            <el-radio-group v-model="params.crowd">
              <el-radio-button label="不限"></el-radio-button>
            </el-radio-group>
          </div>
          <div class="part">
            <span>排除已转化用户</span>
            <el-radio-group v-model="params.exclude_trans">
              <el-radio-button label="不限"></el-radio-button>
            </el-radio-group>
          </div>
          <div class="part">
            <span>操作系统</span>
            <el-radio-group v-model="params.device">
              <el-radio-button label="不限"></el-radio-button>
            </el-radio-group>
          </div>
          <div class="part">
            <span>APP行为</span>
            <el-radio-group v-model="params.app">
              <el-radio-button label="不限"></el-radio-button>
            </el-radio-group>
          </div>
          <div class="part">
            <span>网络</span>
            <el-radio-group v-model="params.network">
              <el-radio-button label="不限"></el-radio-button>
            </el-radio-group>
          </div>
          <div class="part">
            <span>自动扩量</span>
            <el-switch v-model="params.auto_expansion"></el-switch>
          </div>
        </div>
        <div class="per-part">
          <h3>投放出价</h3>
          <div class="part">
            <span>优化目标</span>
            <el-radio-group v-model="params.bid_type">
              <el-radio-button label="转化"></el-radio-button>
            </el-radio-group>
          </div>
          <div class="part">
            <span>付费模式</span>
            <el-radio-group v-model="params.pay_mode">
              <el-radio-button label="oCPM"></el-radio-button>
            </el-radio-group>
          </div>
          <div class="part">
            <span>落地页类型</span>
            <div
              style="
                font-size: 13px;
                font-weight: bold;
                color: rgb(114, 212, 255);
              "
            >
              普通落地页
            </div>
          </div>
          <div class="part">
            <span>推广URL</span>
            <el-input
              v-model="params.ip_url"
              placeholder="推广URL"
              style="width: 300px"
            ></el-input>
          </div>
          <div class="part">
            <span>出价模式</span>
            <div
              style="
                font-size: 13px;
                font-weight: bold;
                color: rgb(114, 212, 255);
              "
            >
              转化出价
            </div>
          </div>
        </div>
        <div class="per-part">
          <h3>单元名称</h3>
          <div class="part">
            <span class="required">单元名称</span>
            <el-input
              v-model="params.adgroup_feed_name"
              placeholder="单元名称"
              style="width: 400px"
              :maxlength="90"
              show-word-limit
            ></el-input>
          </div>
        </div>
        <div class="per-part">
          <h3>程序化设置</h3>
          <div class="part">
            <span class="required">创意名称</span>
            <el-input
              v-model="params.creative_feed_name"
              style="width: 400px"
              show-word-limit
              :maxlength="100"
            ></el-input>
          </div>
          <div class="part">
            <span class="required">计划模式</span>
            <div class="project-type">
              <div class="flex-row-start-center">
                <div>
                  <el-radio-group v-model="projectType">
                    <el-radio-button label="oneToOne"
                      >一对一模式</el-radio-button
                    >
                    <el-radio-button label="manyToMany"
                      >多对多模式</el-radio-button
                    >
                  </el-radio-group>
                </div>
                <div style="margin-left: 20px">
                  <span class="required small-title">分配模式</span>
                  <el-radio-group v-model="distributionType">
                    <el-radio
                      label="average"
                      title="计划根据账号的个数进行平均分配！"
                      >平均</el-radio
                    >
                    <el-radio
                      label="appoint"
                      v-if="projectType === 'oneToOne'"
                      title="为每个计划手动分配计划个数！"
                      >指定</el-radio
                    >
                    <el-radio
                      label="repeat"
                      v-if="projectType === 'oneToOne'"
                      title="每个账号创建相同的计划内容!"
                      >重复</el-radio
                    >
                    <el-radio
                      label="fullPermutation"
                      v-if="projectType === 'manyToMany'"
                      title="每个账号创建的计划个数= 文案个数 * 图片个数"
                      >全排列</el-radio
                    >
                  </el-radio-group>
                </div>
              </div>
            </div>
          </div>
          <div class="part">
            <span
              class="required"
              style="align-self: flex-start; margin-top: 10px"
              >创意物料</span
            >
            <div style="width: 90%" class="y_display_flex_d">
              <!-- 图片上传模式改变 -->
              <div class="picture_mode">
                <div>
                  <el-radio-group v-model="pictureSizeMode">
                    <el-radio-button label="1">小图</el-radio-button>
                    <el-radio-button label="2">大图</el-radio-button>
                    <el-radio-button label="3">固定三图</el-radio-button>
                  </el-radio-group>
                </div>
              </div>
              <div class="display:flex;align-item:center">
                <el-button type="warning" @click="localUploadFile(projectType)"
                  >本地上传</el-button
                >
                <el-button type="primary" @click="uploadImage(projectType)"
                  >选择图片</el-button
                >
                <span
                  style="font-size: 14px; color: #ccc; margin-left: 5px"
                  v-if="
                    projectType === 'oneToOne'
                      ? imageList.length > 0
                      : imageListWithMany.length > 0
                  "
                >
                  ({{
                    projectType === 'oneToOne'
                      ? imageList.length
                      : imageListWithMany.length
                  }}张)
                </span>
                <span style="color: #fd5d5a; font-size: 12px; margin-left: 10px"
                  >(单个中文标点、汉字占2个字符，英文标点、字母、数字占1个字符，一共不超过60个字符)</span
                >
                <countable-textarea
                  v-if="projectType === 'manyToMany'"
                  style="width: 500px; margin-top: 10px"
                  :maxNum="10"
                  :docList="echoDocListWithManyToMany"
                  :maxPerLineLength="60"
                  @exposeValue="
                    (value) => {
                      handleExposeValue(value, 'manyToMany')
                    }
                  "
                ></countable-textarea>
                <div
                  class="image-list flex-row-start-start-wrap"
                  v-if="pictureSizeMode !== '3'"
                >
                  <div
                    v-for="(item, index) in projectType === 'oneToOne'
                      ? imageList
                      : imageListWithMany"
                    :key="index"
                  >
                    <div class="image-item">
                      <img :src="item.image.src" alt />
                      <i
                        class="el-icon-delete-solid del-icon"
                        @click="delImageList(projectType, index)"
                      ></i>
                    </div>
                    <div class="copywriting" v-if="projectType === 'oneToOne'">
                      <el-input
                        v-model="item.text"
                        @change="
                          (val) => {
                            handleInputChange(val, index)
                          }
                        "
                        type="textarea"
                        :ref="`inputText`"
                        style="width: 250px"
                        placeholder="文案"
                        clearable
                      ></el-input>
                      <span class="character-tips">
                        {{ calculateCharacter(item.text) }}
                      </span>
                    </div>
                  </div>
                </div>

                <!-- 固定三张的特殊的样式 单独进行处理 -->
                <div class="image-list image-list-my" v-else>
                  <!-- 先渲染行 -->
                  <div
                    class="image-list-my-item"
                    v-for="(item, index) in projectType === 'oneToOne'
                      ? d2ArrayOneToOne
                      : d2ArrayManyToMany"
                    :key="index"
                  >
                    <div class="item_row">
                      <div class="item_row_top">
                        <div
                          class="image-item image-item-my"
                          v-for="(itemS, indexS) in item.array"
                          :key="indexS"
                        >
                          <img :src="itemS.image.src" alt />
                        </div>
                      </div>

                      <div
                        class="copywriting myCopyRight"
                        v-if="projectType === 'oneToOne'"
                      >
                        <el-input
                          v-model="item.text"
                          @change="
                            (val) => {
                              handleInputChange(val, index)
                            }
                          "
                          type="textarea"
                          :ref="`inputText`"
                          style="width: 150px"
                          placeholder="文案"
                          clearable
                        ></el-input>

                        <span class="character-tips">
                          {{ calculateCharacter(item.text) }}
                        </span>
                      </div>

                      <div
                        class="deleteAll"
                        @click="deleteAll(index, projectType)"
                      >
                        <i class="iconfont icon-lajitong"></i>
                        <span>删除整组</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div class="part">
            <span class="required">创意工具</span>
            <div class="project-type">
              <div class="flex-row-start-center">
                <div>
                  <el-checkbox v-model="progFlag">图片生成视频</el-checkbox>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="per-part">
          <h3>账号列表</h3>
          <div class="part">
            <span
              class="required"
              style="align-self: flex-start; margin-top: 10px"
              >选择账号</span
            >
            <div>
              <div>
                <el-button type="primary" @click="handleAddAccount"
                  >添加账号</el-button
                >
                <div
                  style="
                    display: inline-block;
                    font-size: 14px;
                    margin-left: 10px;
                    margin-right: 20px;
                  "
                >
                  <span class="required" style="margin-right: 10px"
                    >价格模式</span
                  >
                  <el-radio-group v-model="ocpcBidType">
                    <el-radio label="APPOINT">指定</el-radio>
                    <el-radio label="UNIFY">统一</el-radio>
                  </el-radio-group>
                </div>
                <span
                  class="margin-left-ten"
                  style="font-size: 12px; color: #ccc"
                  v-if="
                    baiduAccountList.length > 0 &&
                    distributionType === 'appoint' &&
                    projectType === 'oneToOne'
                  "
                >
                  <span>
                    请为每个账号分配计划个数
                    <span style="color: #f56868"
                      >(如果分配的总数超过拟创建的计划总数，则超出的部分不会生效!)</span
                    >
                  </span>
                </span>
              </div>

              <div
                class="account-list"
                v-if="baiduAccountList.length > 0"
                @mouseleave="handleAccountMouseLeave"
              >
                <div class="account-list-container">
                  <div
                    class="account-item"
                    v-for="(item, index) in baiduAccountList"
                    :key="item.targetId"
                    :ref="`AccountItem`"
                  >
                    <div
                      class="account"
                      @mouseenter="handleAccountMouseEnter(item, index)"
                      @click="setAllocationNum(item, index)"
                    >
                      {{ item.targetName }}
                      <span v-if="item.ocpc_bid" style="color: #ccc"
                        >({{ item.ocpc_bid }})</span
                      >
                      <span class="delete-item" @click="deleteItem(index)">
                        <i class="el-icon-error"></i>
                      </span>
                      <span
                        class="distributionType-number"
                        v-if="distributionType === 'appoint'"
                        >{{ item.number ? item.number : 0 }}</span
                      >
                    </div>
                    <div class="extra-operation">
                      <span class="required">域名</span>：
                      <el-select
                        v-model="item.link"
                        placeholder="请选择域名"
                        clearable
                        filterable
                        style="margin-right: 20px; width: 400px"
                      >
                        <el-option
                          v-for="val in landPageList"
                          :key="val.id"
                          :value="val.link"
                        >
                          <div class="custom-option-style">
                            <span>{{ val.link }}</span>
                            <span>{{ val.channelName }}</span>
                          </div>
                        </el-option>
                      </el-select>
                      公众号名称：<el-tag>{{
                        item.channelName || '--'
                      }}</el-tag>
                    </div>

                    <div
                      class="hover-desk box-shadow-light-grey"
                      :ref="`hoverItem`"
                      v-if="ocpcBidType === 'APPOINT'"
                      @mouseleave.stop="handleAccountMouseLeave"
                    >
                      <div class="flex-row-start-center">
                        <span class="required">出价</span>
                        <c-input
                          style="width: 120px"
                          class="margin-left-ten"
                          :onlyNum="true"
                          :min="0.3"
                          :max="999.9"
                          v-model="item.ocpc_bid"
                        ></c-input>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="part" v-if="ocpcBidType === 'UNIFY'">
            <span class="required">统一出价</span>
            <c-input
              v-model="unifyPrice"
              :onlyNum="true"
              style="width: 200px"
            ></c-input>
            <div style="font-size: 12px; color: #ccc; margin-left: 10px">
              (即使单个账号填入了出价，最后以统一出价为准)
            </div>
          </div>
        </div>
        <div class="per-part">
          <h3>模板名称</h3>
          <div class="part">
            <span class="required">模板名称</span>
            <el-input
              v-model="templateName"
              placeholder="模板名称"
              clearable
              style="width: 300px"
            ></el-input>
          </div>
        </div>
      </div>
      <div class="button-list">
        <el-button
          plain
          type="primary"
          :disabled="checkDisabledStatus()"
          title="请正确选择账号、创意物料图片，正确填写创意标题和创意名称！！！"
          @click="handlePreview"
          >预览</el-button
        >
        <c-button
          type="primary"
          @click="
            (cb) => {
              handleSubmit(cb)
            }
          "
          >保存</c-button
        >
        <span class="tips" style="margin-left: 10px; font-size: 13px">
          请正确
          <span>选择账号</span>、 <span>创意物料图片</span>，正确填写
          <span>创意标题</span>和
          <span>创意名称！！！</span>
        </span>
      </div>
    </template>
    <template v-else>
      <dy-table
        height="700px"
        :loading="loading"
        :dataList="templateDataList"
        :showPagination="true"
        :page="page"
        :pageSize="pageSize"
        :total="total"
        :isUseCheckbox="true"
        :rowList="templateRowList"
        @pageChange="pageChange"
        @selection-change="handleSelectionChange"
      >
        <template #filter>
          <el-button @click="initData">刷新数据</el-button>
          <el-button @click="addTemplate" type="primary">新增</el-button>
          <el-input
            v-model="search"
            clearable
            placeholder="请输入账号名称"
            style="width: 300px; margin-left: 10px; margin-right: 10px"
          >
            <el-button
              slot="append"
              icon="el-icon-search"
              @click="refreshData(1)"
            ></el-button>
          </el-input>
          <c-button
            :disabled="selection.length === 0"
            @confirm="
              (cb) => {
                handleBatchDelete(cb)
              }
            "
            :autoConfirm="true"
            type="danger"
            >批量删除</c-button
          >
        </template>
        <template #createdTime="{ row }">
          <span>{{ row.createdTime | customTime }}</span>
        </template>
        <template #operation="{ row }">
          <el-button class="button-small" @click="checkTemplateDetail(row.id)"
            >详情</el-button
          >
          <el-button
            type="primary"
            class="button-small"
            @click="handleEditItem(row)"
            >编辑</el-button
          >
          <c-button
            type="danger"
            size="small"
            class="button-small"
            :autoConfirm="true"
            @confirm="
              (cb) => {
                handleDelete(cb, row.id)
              }
            "
            >删除</c-button
          >
        </template>
      </dy-table>
    </template>
    <template v-if="showDrawer">
      <el-drawer
        title="选择账号"
        append-to-body
        size="1000px"
        height="750px"
        :before-close="
          () => {
            handleClose('table')
          }
        "
        :visible.sync="showDrawer"
      >
        <dy-table
          ref="table"
          refName="mainTable"
          :isUseCheckbox="true"
          :dataList="accountList"
          :rowList="accountRowList"
          @selection-change="
            (select) => {
              selectionChange('table', select)
            }
          "
          @handleRowClick="
            (row) => {
              handleRowClick('table', 'mainTable', row)
            }
          "
        >
          <template #operation="{ row }">
            <el-button
              size="small"
              class="button-small"
              @click="checkAccountDetail(row)"
              >账号详情</el-button
            >
          </template>
        </dy-table>
        <div style="margin-top: 20px">
          <el-button type="primary" @click="handleOk('table')">确定</el-button>
          <el-button @click="handleClose('table', false)">取消</el-button>
        </div>
      </el-drawer>
    </template>
    <template v-if="showSubDrawer">
      <el-drawer
        title="子账号列表"
        size="800px"
        append-to-body
        :before-close="
          () => {
            handleClose('subDrawer')
          }
        "
        :visible.sync="showSubDrawer"
      >
        <dy-table
          ref="suTable"
          :dataList="subAccountList"
          :rowList="subAccountRowList"
          :isUseCheckbox="true"
          :refName="'subTable'"
          height="750px"
          @selection-change="
            (select) => {
              selectionChange('subDrawer', select)
            }
          "
          @handleRowClick="
            (row) => {
              handleRowClick('suTable', 'subTable', row)
            }
          "
        >
          <template #filter>
            <el-input
              v-model="targetName"
              placeholder="请输入账户名!"
              style="width: 300px"
            >
              <el-button
                slot="append"
                icon="el-icon-search"
                @click="handleSearch"
              ></el-button>
            </el-input>
          </template>
          <template #status="{ row }">
            <el-tag v-if="row.status === 1">已绑定</el-tag>
            <el-tag v-else-if="row.status === 0" type="warning">未接入</el-tag>
            <el-tag v-else-if="row.status === 2" type="danger">已解绑</el-tag>
          </template>
        </dy-table>
        <div>
          <el-button type="primary" @click="handleOk('subDrawer')"
            >确定</el-button
          >
          <el-button @click="handleClose('subDrawer', false)">取消</el-button>
        </div>
      </el-drawer>
    </template>
    <el-dialog
      title="图片素材"
      width="1500px"
      v-dialogDrag
      top="10px"
      append-to-body
      :visible.sync="showPicDialog"
      :before-close="handleCancel"
      custom-class="my_private_dia"
    >
      <div class="picture_dialog">
        <picture-library
          :show="showPicDialog"
          :onlySingle="true"
          ref="picLibrary"
          :maxNum="maxNum"
          :pictureSizeMode="pictureSizeMode"
          @transformData="transformData"
          :groupListP="groupList"
        >
          <div slot="footer" style="margin-top: 10px">
            <el-button
              type="primary"
              @click="
                () => {
                  handleConfirm()
                }
              "
              >确定</el-button
            >
            <el-button @click="handleCancel">取消</el-button>
          </div>
        </picture-library>
      </div>
    </el-dialog>
    <preview-item
      :show="showPreviewDialog"
      :data="previewData"
      @close="showPreviewDialog = false"
      @saveData="saveData"
    ></preview-item>
    <single-template-list
      :data="singleTemplateList"
      :singleTemplateId="singleTemplateId"
      :show="showSingleTemplateDialog"
      @close="showSingleTemplateDialog = false"
    ></single-template-list>
    <baidu-account-list
      :show="showBaiduAccList"
      :isSubmit="isSubmit"
      :baiduAccountList="baiduAccountList"
      @chooseSuccess="handleChooseSuccess"
      @close="showBaiduAccList = false"
    ></baidu-account-list>
    <add-picture
      :canUploadBatchPic="false"
      :show="showAddPicDialog"
      @close="showAddPicDialog = false"
      @transformData="transformData"
    ></add-picture>
  </div>
</template>

<script>
import { getBaiduApiChannel } from '@/api/account'
import {
  addBaiduPlan,
  getBaiduPlanList,
  getTemplateList,
  delTemplateList,
} from '@/api/baidu'
import { getGroundList } from '@/api/groundPage'
import {
  chunk,
  deepFlatten,
  calculateCharacter,
  formatTime,
} from '@/assets/js/utils'
import { mockRegionData } from '@/mockData'
import PictureLibrary from './picture-library.vue'
import PreviewItem from './preview.vue'
import CountableTextarea from './countable-textarea.vue'
import SingleTemplateList from './single-template-list.vue'
import BaiduAccountList from './baidu-account-list.vue'
import AddPicture from '@/views/material-warehouse/library/add-picture'
const CHARACTER_LENGTH = 60
export default {
  data() {
    return {
      subId: '',
      page: 1,
      search: '',
      total: 0,
      isEdit: false,
      pageSize: 15,
      imageList: [],
      isSave: true,
      targetName: '',
      isSubmit: false, // !是否提交成功
      accountList: [],
      showDrawer: false,
      subAccountList: [],
      showSubDrawer: false,
      chooseAccountList: [],
      copySubAccountList: [], // ! subAccountList的副本 用于静态筛选
      showAddPicDialog: false,
      showBaiduAccList: false,
      subAccountRowList: [
        {
          label: 'ID',
          prop: 'id',
        },
        {
          label: '账户名',
          prop: 'targetName',
        },
        {
          label: '状态',
          slot: true,
          prop: 'status',
        },
      ],
      showPicDialog: false,
      templateDataList: [],
      accountName: '',
      accountRowList: [
        {
          label: '账号',
          prop: 'userName',
        },
        {
          label: '创建时间',
          prop: 'createdTime',
        },
        {
          label: '更新时间',
          prop: 'updatedTime',
        },
        {
          label: '操作',
          action: 'operation',
        },
      ],
      isRenderAuto: false,
      previewData: [],
      curPreviewData: null, // 保存之前的数据
      showPreviewDialog: false,
      preTableList: null, // 上一次table被选中的合集
      curTableList: null, //  当前table被选中的合集
      spanArr: [],
      pos: 0,
      budType: '不限',
      templateRowList: [
        {
          label: 'id',
          prop: 'id',
        },
        {
          label: '模板名称',
          prop: 'templateName',
          popover: true,
        },
        {
          label: '创建时间',
          prop: 'createdTime',
          slot: true,
        },
        {
          label: '操作',
          action: 'operation',
        },
      ],
      loading: false,
      selection: [],
      showDocDialog: false,
      tagInfo: [
        {
          tagName: 'planStatus',
          tagVal: [
            {
              val: 1,
              text: '未上传',
            },
            {
              val: 2,
              text: '待上传',
            },
            {
              val: 3,
              text: '已上传',
            },
          ],
        },
      ],
      imageListWithMany: [],
      chooseType: 'oneToOne', // 选择的计划模式
      maxNum: 120, // 最多勾选的图片数量
      docList: [], // 一对一文案
      docWithManyList: [], // 多对多文案
      projectType: 'oneToOne', // 模式选择
      distributionType: 'average', // 分配规则
      manyToManyProjectList: [], // 多对多模式计划合集
      regionType: '不限',
      regionData: mockRegionData,
      templateName: '', //  模板名称
      singleTemplateList: [],
      showSingleTemplateDialog: false,
      baiduAccountList: [], // ! chooseAccountList 替代 百度账号列表
      echoDocListWithManyToMany: [], //
      ocpcBidType: 'APPOINT', // 价格分配模式
      unifyPrice: '', // !统一出价
      singleTemplateId: null, // 当前模板计划的id
      landPageList: [], // 落地页列表
      params: {
        f_business: '', // 单元设置-推广业务
        consumption: '标准投放', // 消耗方式
        bud_get: '', // 预算
        promotionDate: {
          type: '不限',
          value: [],
        }, // 推广日期
        promotionPeriod: '不限',
        campaign_feed_name: '', // 名字
        f_types: '百度信息流', // 投放范围
        region: '不限',
        age: {
          text: '不限',
          startRange: '',
          endRange: '',
        },
        sex: '男', // 性别
        life_stage: '不限',
        education: '不限',
        new_interests: '不限',
        key_words: '不限', // 意图词
        crowd: '不限', // 人群包
        exclude_trans: '不限', // 排除已转化用户
        device: '不限', // 操作系统
        app: '不限', // APP行为
        network: '不限',
        auto_expansion: false,
        bid_type: '转化', // 优化目标
        pay_mode: 'oCPM', // 付费模式
        ip_url: '', // !推广URL
        adgroup_feed_name: '', // 单元名称
        creative_feed_name: '', // 创意名称
        creative_title: [
          // 文案
          {
            text: '',
          },
        ],
      },

      // youngDan
      groupList: {}, //选中的图片对象
      progFlag: false,
      pictureSizeMode: '1', //选择上传图片的模式  1：单小图 2：单大图  3：固定三图
      d2ArrayOneToOne: [],
      d2ArrayManyToMany: [],
    }
  },
  filters: {
    customTime(value) {
      return formatTime(value)
    },
  },
  watch: {
    pictureSizeMode() {
      //  切换的时候把图片清空
      this.imageList = []
      this.imageListWithMany = []
      this.groupList = {}
      this.d2ArrayOneToOne = []
      this.d2ArrayManyToMany = []
    },
    showSubDrawer: {
      handler(newV) {
        if (newV) {
          this.echoSubTableData()
        } else {
          this.targetName = ''
        }
      },
    },
    showDrawer: {
      handler(newV) {
        if (newV) {
          this.$nextTick(() => {
            this.accountList.forEach((item) => {
              if (
                !this.chooseAccountList.find(
                  (account) => account.name === item.userName
                )
              ) {
                this.$refs.table.$refs.mainTable.toggleRowSelection(item, false)
              }
            })
          })
        }
      },
    },
    projectType: {
      handler(newV) {
        this.imageList = []
        this.distributionType = 'average'
        this.docWithManyList = []
        this.imageListWithMany = []
        this.d2ArrayOneToOne = []
        this.d2ArrayManyToMany = []
        this.groupList = {}
      },
    },
    'params.age.text': {
      handler(newV) {
        if (newV === '不限') {
          this.params.age = {
            text: '不限',
            startRange: '',
            endRange: '',
          }
        }
      },
    },
    regionType: {
      handler(newV) {
        this.params.region = newV === '不限' ? '不限' : ''
      },
    },
  },
  mounted() {
    this.initData()
    this.createBeforeunloadHandler()
  },
  methods: {
    calculateCharacter(value) {
      return calculateCharacter(value)
    },
    initData() {
      getTemplateList({
        page: this.page,
        pageSize: this.pageSize,
        templateName: this.search,
      }).then((res) => {
        this.templateDataList = res.list
        this.total = res.total
      })
    },
    getSpanArr(data) {
      this.spanArr = []
      for (let i = 0; i < data.length; i++) {
        if (i === 0) {
          this.spanArr.push(1)
          this.pos = 0
        } else {
          // 判断当前元素与上一个元素是否相同 (type要根据要合并的元素更改)
          if (data[i].targetName === data[i - 1].targetName) {
            this.spanArr[this.pos] += 1
            this.spanArr.push(0)
          } else {
            this.spanArr.push(1)
            this.pos = i
          }
        }
      }
    },
    uploadImage(type) {
      this.chooseType = type
      this.maxNum = type === 'manyToMany' ? 10 : 120
      this.showPicDialog = true
    },
    localUploadFile(type) {
      this.chooseType = type
      this.maxNum = type === 'manyToMany' ? 10 : 120
      this.showAddPicDialog = true
    },
    handlePreview() {
      if (this.curPreviewData) {
        this.previewData = JSON.parse(JSON.stringify(this.curPreviewData))
        this.showPreviewDialog = true
        return
      }
      const flag = this.handleCreatePreviewData()
      if (flag) {
        this.showPreviewDialog = true
      }
    },
    // 平均分配的时候
    averageArr(accountList, projectList, size, obj = {}, type) {
      const chunkArr = chunk(projectList, size) //如果是平均分配的话 会分配成[1,2]  [3,4]
      // 重复分配的话 假如有两个账号四张图  那么一个账号都存在四个计划  就会生成八条计划
      accountList.forEach((account, index) => {
        const chunkList = type === 'repeat' ? chunkArr[0] : chunkArr[index]
        chunkList.forEach((chunk) => {
          let currentObj = {
            ...this.params,
            promotionDate: JSON.stringify(this.params.promotionDate),
            baidu_channel_marketing_id: account.childId, //假如 有四张图  生成四个计划 两个账号  那么前两条计划的id是一样的（平均分配）
            progFlag: this.progFlag ? 1 : 2,
            materialType: this.pictureSizeMode,
            bud_get: this.budType === '不限' ? 0 : +this.params.bud_get,
            ocpc_bid:
              this.ocpcBidType === 'APPOINT'
                ? account.ocpc_bid
                : this.unifyPrice,
            projectType: this.projectType,
            distributionType: this.distributionType,
            link: account.link,
            creative_title: chunk.text,
          }

          if (this.pictureSizeMode === '3') {
            let three_figure_ids = chunk.array.map((item) => {
              return item.image.id
            })
            currentObj['three_figure_ids'] = three_figure_ids
          } else {
            currentObj['material_id'] = chunk.image.id
            currentObj['image'] = chunk.image
          }

          ;(obj[account.targetName] || (obj[account.targetName] = [])).push(
            currentObj
          )
        })
      })
      return obj
    },
    //  提交的时候 处理参数
    handleCreatePreviewData() {
      const totalAccount = this.baiduAccountList.length
      const obj = {}
      if (this.projectType === 'oneToOne') {
        if (this.distributionType === 'average') {
          // ! 无法均分
          if (this.pictureSizeMode === '3') {
            // 固定三张的情况下
            if (this.d2ArrayOneToOne.length % totalAccount !== 0) {
              this.$message.error(
                '当前账户数与计划总数无法均分，请调整计划和账号的个数'
              )
              return false
            }
          } else {
            if (this.imageList.length % totalAccount !== 0) {
              this.$message.error(
                '当前账户数与计划总数无法均分，请调整计划和账号的个数'
              )
              return false
            }
          }

          // ! 平均分配
          let size
          if (this.pictureSizeMode === '3') {
            size = this.d2ArrayOneToOne.length / totalAccount
            this.averageArr(
              this.baiduAccountList,
              this.d2ArrayOneToOne,
              size,
              obj
            )
          } else {
            size = this.imageList.length / totalAccount
            this.averageArr(this.baiduAccountList, this.imageList, size, obj)
          }

          this.previewData = Object.entries(obj)
        }
        // ! 指定分配

        if (this.distributionType === 'appoint') {
          let startIndex = 0
          const alreadyAllocationList = JSON.parse(
            JSON.stringify(this.baiduAccountList)
          ).filter((item) => item.number)
          const totalNumber = this.getTotalNumber()
          if (totalNumber === 0) {
            this.$message.warning('请为每个账号分配计划个数！')
            return false
          }

          alreadyAllocationList.forEach((account, index) => {
            const { number } = account
            let chunkArr
            if (this.pictureSizeMode === '3') {
              chunkArr = JSON.parse(JSON.stringify(this.d2ArrayOneToOne)).slice(
                startIndex,
                startIndex + +number
              )
            } else {
              chunkArr = JSON.parse(JSON.stringify(this.imageList)).slice(
                startIndex,
                startIndex + +number
              )
            }

            startIndex += +number
            chunkArr.forEach((chunk) => {
              let currentObj = {
                ...this.params,
                promotionDate: JSON.stringify(this.params.promotionDate),
                baidu_channel_marketing_id: account.childId,
                progFlag: this.progFlag ? 1 : 2,
                materialType: this.pictureSizeMode,
                bud_get: this.budType === '不限' ? 0 : +this.params.bud_get,
                ocpc_bid:
                  this.ocpcBidType === 'APPOINT'
                    ? account.ocpc_bid
                    : this.unifyPrice,
                projectType: this.projectType,
                distributionType: this.distributionType,
                link: account.link,
                creative_title: chunk.text,
              }

              if (this.pictureSizeMode === '3') {
                let three_figure_ids = chunk.array.map((item) => {
                  return item.image.id
                })
                currentObj['three_figure_ids'] = three_figure_ids
              } else {
                currentObj['image'] = chunk.image
                currentObj['material_id'] = chunk.image.id
              }

              ;(obj[account.targetName] || (obj[account.targetName] = [])).push(
                currentObj
              )
            })
          })
          this.previewData = Object.entries(obj)
        }
        // ! 重复分配

        if (this.distributionType === 'repeat') {
          if (this.pictureSizeMode === '3') {
            this.averageArr(
              this.baiduAccountList,
              this.d2ArrayOneToOne,
              this.d2ArrayOneToOne.length,
              obj,
              'repeat'
            )
          } else {
            this.averageArr(
              this.baiduAccountList,
              this.imageList,
              this.imageList.length,
              obj,
              'repeat'
            )
          }

          this.previewData = Object.entries(obj)
        }
        return true
      }
      // * 多对多
      if (this.projectType === 'manyToMany') {
        if (!this.docWithManyList.length) {
          this.$message.warning('文案不能为空')
          return false
        }
        this.manyToManyProjectList = []
        if (this.pictureSizeMode === '3') {
          for (let i = 0; i < this.docWithManyList.length; i++) {
            for (let j = 0; j < this.d2ArrayManyToMany.length; j++) {
              this.manyToManyProjectList.push({
                array: this.d2ArrayManyToMany[j].array,
                text: this.docWithManyList[i],
              })
            }
          }
        } else {
          for (let i = 0; i < this.docWithManyList.length; i++) {
            for (let j = 0; j < this.imageListWithMany.length; j++) {
              this.manyToManyProjectList.push({
                image: this.imageListWithMany[j].image,
                text: this.docWithManyList[i],
              })
            }
          }
        }

        if (this.distributionType === 'average') {
          // ! 平均分配
          if (this.manyToManyProjectList.length % totalAccount !== 0) {
            this.$message.error(
              '当前账户数与计划总数无法均分，请调整计划和账号的个数'
            )
            return false
          }
          const size = this.manyToManyProjectList.length / totalAccount
          this.averageArr(
            this.baiduAccountList,
            this.manyToManyProjectList,
            size,
            obj
          )
          this.previewData = Object.entries(obj)
        }

        // ! 全排列
        if (this.distributionType === 'fullPermutation') {
          for (let i = 0; i < this.baiduAccountList.length; i++) {
            const account = this.baiduAccountList[i]
            for (let j = 0; j < this.manyToManyProjectList.length; j++) {
              const pItem = this.manyToManyProjectList[j]
              let currentObj = {
                ...this.params,
                promotionDate: JSON.stringify(this.params.promotionDate),
                creative_title: pItem.text,
                baidu_channel_marketing_id: account.childId,
                progFlag: this.progFlag ? 1 : 2,
                materialType: this.pictureSizeMode,
                bud_get: this.budType === '不限' ? 0 : +this.params.bud_get,
                ocpc_bid:
                  this.ocpcBidType === 'APPOINT'
                    ? account.ocpc_bid
                    : this.unifyPrice,
                projectType: this.projectType,
                distributionType: this.distributionType,
                link: account.link,
              }

              if (this.pictureSizeMode === '3') {
                let three_figure_ids = pItem.array.map((item) => {
                  return item.image.id
                })
                currentObj['three_figure_ids'] = three_figure_ids
              } else {
                currentObj['image'] = pItem.image
                currentObj['material_id'] = pItem.image.id
              }

              ;(obj[account.targetName] || (obj[account.targetName] = [])).push(
                currentObj
              )
            }
          }
        }
        this.previewData = Object.entries(obj)
        return true
      }
    },
    checkDisabledStatus() {
      if (this.projectType === 'oneToOne') {
        if (this.pictureSizeMode === '3') {
          return (
            this.d2ArrayOneToOne.length === 0 ||
            this.d2ArrayOneToOne.some((item) => !item.text) ||
            this.baiduAccountList.length === 0
          )
        } else {
          return (
            this.imageList.length === 0 ||
            this.imageList.some((item) => !item.text) ||
            // this.chooseAccountList.length === 0
            this.baiduAccountList.length === 0
          )
        }
      }
      if (this.projectType === 'manyToMany') {
        if (this.pictureSizeMode === '3') {
          return (
            this.imageListWithMany.length === 0 ||
            this.docWithManyList.every((item) => !item) ||
            // this.chooseAccountList.length === 0
            this.baiduAccountList.length === 0
          )
        } else {
          return (
            this.d2ArrayManyToMany.length === 0 ||
            this.docWithManyList.every((item) => !item) ||
            // this.chooseAccountList.length === 0
            this.baiduAccountList.length === 0
          )
        }
      }
    },
    handleAddAccount() {
      this.showBaiduAccList = true
    },
    handleClose(type, flag) {
      if (type === 'table') {
        this.showDrawer = false
      }
      if (type === 'subDrawer') {
        this.showSubDrawer = false
      }
    },
    async selectionChange(type, select) {
      if (type === 'table') {
        if (!this.preTableList) {
          this.preTableList = JSON.parse(JSON.stringify(select))
        }
        if (!this.curTableList) {
          this.curTableList = JSON.parse(JSON.stringify(select))
        }

        this.curTableList = JSON.parse(JSON.stringify(select))

        const nameList = select.map((item) => item.userName)
        for (let i = 0; i < nameList.length; i++) {
          const item = nameList[i]
          const targetIndex = this.chooseAccountList.findIndex(
            (i) => i.name === item
          )
          if (targetIndex === -1) {
            const subAccountList = await getBaiduApiChannel({
              id: select[i].id,
            })
            this.chooseAccountList.push({
              name: item,
              children: subAccountList,
            })
          } else {
            // 冷兄注释的
            // this.$set(this.chooseAccountList, targetIndex, {
            //   name: item,
            //   // children: this.subAccountList
            //   children:this.chooseAccountList
            // })
          }
        }
        // ! 如果 之前的item被选中，之后没被选中 ，则需要给没被选中的item的children置空
        this.$nextTick(() => {
          const needResetChildrenItemList = this.preTableList.filter(
            (preItem) => {
              return !this.curTableList.find(
                (curItem) => curItem.id === preItem.id
              )
            }
          )
          needResetChildrenItemList.forEach((item) => {
            const targetIndex = this.chooseAccountList.findIndex(
              (item2) => item2.name === item.userName
            )
            if (targetIndex > -1) {
              this.chooseAccountList.splice(targetIndex, 1)
            }
          })
          this.preTableList = JSON.parse(JSON.stringify(select))
        })
      }
      if (type === 'subDrawer') {
        if (this.isRenderAuto) {
          return
        }
        // ! 获取选中的行
        const selectedRow =
          this.$refs.suTable.$refs.subTable.store.states.selection
        this.subAccountList.forEach((sub) => {
          sub.checked = !!selectedRow.find(
            (item) => item.targetName === sub.targetName
          )
          const Index = this.copySubAccountList.findIndex(
            (copySub) => copySub.targetName === sub.targetName
          )
          if (Index > -1) {
            this.copySubAccountList[Index].checked = sub.checked
          }
        })
      }
    },
    checkAccountDetail(row) {
      this.subId = row.id
      this.accountName = row.userName
      getBaiduApiChannel({
        id: this.subId,
        targetName: this.targetName,
      }).then((res) => {
        res.forEach((item) => {
          item.checked = false
        })
        this.subAccountList = res
        this.copySubAccountList = JSON.parse(JSON.stringify(res))
        this.showSubDrawer = true
      })
    },
    handleSearch() {
      this.isRenderAuto = true
      this.subAccountList = this.copySubAccountList.filter((item) =>
        item.targetName.includes(this.targetName)
      )
      this.$nextTick(() => {
        this.subAccountList.forEach((item) => {
          if (item.checked) {
            this.$refs.suTable.$refs.subTable.toggleRowSelection(item, true)
          }
        })
        this.$nextTick(() => {
          this.isRenderAuto = false
        })
      })
    },
    handleOk(type) {
      if (type === 'subDrawer') {
        // ! 获取已勾选的行
        const targetIndex = this.chooseAccountList.findIndex(
          (item) => item.name === this.accountName
        )

        const selectedList = this.copySubAccountList.filter(
          (item) => item.checked
        )
        if (targetIndex > -1) {
          this.chooseAccountList[targetIndex].children = selectedList
        } else {
          this.chooseAccountList.push({
            name: this.accountName,
            children: selectedList,
          })
        }
        this.showSubDrawer = false
      }

      if (type === 'table') {
        this.showDrawer = false
      }
    },
    echoSubTableData() {
      this.$nextTick(() => {
        const targetIndex = this.chooseAccountList.findIndex(
          (item) => item.name === this.accountName
        )
        if (targetIndex > -1) {
          const subChild = this.chooseAccountList[targetIndex].children
          this.isRenderAuto = true

          this.copySubAccountList.forEach((item) => {
            if (subChild.find((child) => child.id === item.id)) {
              item.checked = true
            } else {
              item.checked = false
            }
          })
          this.subAccountList = JSON.parse(
            JSON.stringify(this.copySubAccountList)
          )
          this.$nextTick(() => {
            this.subAccountList.forEach((item) => {
              if (item.checked) {
                this.$refs.suTable.$refs.subTable.toggleRowSelection(item, true)
              }
            })
            this.$nextTick(() => {
              this.isRenderAuto = false
            })
          })
        }
      })
    },
    cancel(type) {
      if (type === 'subDrawer') {
        this.targetName = ''
        this.chooseSubAccountList = []
      }
      if (type === 'table') {
        this.chooseAccountList = []
      }
    },
    createBeforeunloadHandler() {
      window.addEventListener('beforeunload', this.beforeunloadHandler, false)
    },
    destroyedBeforeunloadHandler() {
      window.removeEventListener(
        'beforeunload',
        this.beforeunloadHandler,
        false
      )
    },
    saveData(data) {
      if (
        this.projectType === 'oneToOne' &&
        this.distributionType === 'appoint'
      ) {
        if (!data) return
        data.forEach((item) => {
          const list = item[1] // 获取预览过后每个账号的计划个数
          const targetIndex = -1
          const subIndex = -1
          const targetItem = null
          const temporaryData = JSON.parse(
            JSON.stringify(this.baiduAccountList)
          )
          temporaryData.forEach((item, index) => {
            if (+item.number !== list.length) {
              item.number = list.length
            }
          })
          this.baiduAccountList = JSON.parse(JSON.stringify(temporaryData))
        })
      } else {
        this.curPreviewData = data
      }
    },
    handleSubmit(cb) {
      if (!this.params.campaign_feed_name) {
        return this.showMessage('请输入计划名称!', 'warning', cb)
      }

      if (!this.params.creative_feed_name) {
        return this.showMessage('请输入创意名称!', 'warning', cb)
      }
      if (!this.params.adgroup_feed_name) {
        return this.showMessage('请输入单元名称!', 'warning', cb)
      }
      if (this.params.age.startRange || this.params.age.endRange) {
        if (!this.params.age.startRange || !this.params.age.endRange) {
          return this.showMessage('请完善年龄', 'warning', cb)
        }
      }
      if (!this.baiduAccountList.length) {
        return this.showMessage('请选择账号!', 'warning', cb)
      }
      if (this.regionType === '自定义') {
        if (!this.params.region) {
          return this.showMessage('请选择地域！', 'warning', cb)
        }
      }
      if (!this.templateName) {
        return this.showMessage('请输入模板名称!', 'warning', cb)
      }
      if (this.checkDisabledStatus()) {
        return this.showMessage('请完善图文计划!', 'warning', cb)
      }
      if (this.ocpcBidType === 'APPOINT') {
        if (this.baiduAccountList.some((account) => !account.ocpc_bid)) {
          return this.showMessage('请完善账号的出价!', 'warning', cb)
        }
      } else {
        if (!this.unifyPrice) {
          return this.showMessage('请完善统一出价!', 'warning', cb)
        }
      }

      // 冷兄注释的
      // // ! 新增账号域名判断
      // if (this.baiduAccountList.some(account => !account.link)) {
      //   return this.showMessage('请完善账号域名!', 'warning', cb)
      // }

      let isCanSubmit = false
      if (this.curPreviewData) {
        this.previewData = deepFlatten(
          JSON.parse(JSON.stringify(this.curPreviewData)).map((item) => item[1])
        )
        isCanSubmit = true
      } else {
        const flag = this.handleCreatePreviewData()
        if (flag) {
          this.previewData = deepFlatten(
            this.previewData.map((item) => item[1])
          )
        }
        isCanSubmit = flag
      }

      if (isCanSubmit) {
        addBaiduPlan({
          plans: this.previewData,
          template: {
            template_name: this.templateName,
          },
        })
          .then(() => {
            this.isSave = true
            this.$nextTick(() => {
              this.resetData()
              localStorage.removeItem('templateData')
              this.isSubmit = true
              this.initData()
            })
          })
          .finally(() => {
            cb()
          })
      } else {
        cb()
      }
    },
    setAllocationNum(val, index) {
      if (this.distributionType !== 'appoint') return
      if (this.pictureSizeMode === '3') {
        if (!this.d2ArrayOneToOne.length) {
          return this.$message.warning('请选择图片!')
        }
      } else {
        if (!this.imageList.length) {
          return this.$message.warning('请选择图片!')
        }
      }

      // ! 计算总的已经分配的计划个数
      const totalNumber = this.getTotalNumber()

      if (this.pictureSizeMode === '3') {
        this.$prompt(
          `请设置计划个数(剩余可分配${
            this.d2ArrayOneToOne.length - totalNumber >= 0
              ? this.d2ArrayOneToOne.length - totalNumber
              : 0
          }个)`,
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          }
        )
          .then(({ value }) => {
            this.$set(this.baiduAccountList, index, {
              ...val,
              number: +value,
            })
          })
          .catch(() => {})
      } else {
        this.$prompt(
          `请设置计划个数(剩余可分配${
            this.imageList.length - totalNumber >= 0
              ? this.imageList.length - totalNumber
              : 0
          }个)`,
          '提示',
          {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
          }
        )
          .then(({ value }) => {
            this.$set(this.baiduAccountList, index, {
              ...val,
              number: +value,
            })
          })
          .catch(() => {})
      }
    },
    handleExposeValue(value, type = 'oneToOne') {
      if (type === 'oneToOne') {
        this.docList = value
      }
      if (type === 'manyToMany') {
        this.docWithManyList = value.filter((item) => !!item.trim())
      }
    },
    handleDelete(cb, id) {
      delTemplateList(id)
        .then(() => {
          this.$message.success('删除成功!')

          this.initData()
        })
        .finally(() => {
          if (typeof cb === 'function') {
            cb()
          }
        })
    },
    delImageList(type, index) {
      if (type === 'oneToOne') {
        this.imageList.splice(index, 1)
      }
      if (type === 'manyToMany') {
        this.imageListWithMany.splice(index, 1)
      }
    },
    handleCheckChange(curNodeData, allNodeData) {
      const { checkedNodes } = allNodeData
      const fatherNode = checkedNodes.find((node) => node.id === 1)
      this.params.region = fatherNode
        ? fatherNode.label
        : checkedNodes.map((item) => item.label).join(',')
    },
    handleBack() {
      this.$confirm('确定要返回吗？现有未保存的数据将会删除!', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          this.isSave = true
        })
        .catch(() => {})
    },
    beforeunloadHandler(e) {
      if (!this.isSave) {
        e = e || window.event
        if (e) {
          e.returnValue = '确定要继续吗？未保存的数据将会丢失!'
        }
        return '关闭提示'
      }
    },
    handleCancel() {
      if (this.$refs.picLibrary) {
        this.$refs.picLibrary.resetData()
      }
      this.showPicDialog = false
    },
    handleConfirm(data = null) {
      let chooseImageList = []
      this.d2ArrayOneToOne = []
      this.d2ArrayManyToMany = []

      if (!data) {
        if (this.pictureSizeMode !== '3') {
          chooseImageList = JSON.parse(
            JSON.stringify(this.$refs.picLibrary.imageList)
          )
        } else {
          // 当是三个固定图片的时候 拿到组件内的图形绑定对象
          let myCurrentObj = JSON.parse(
            JSON.stringify(this.$refs.picLibrary.groupList)
          )

          this.groupList = myCurrentObj

          let keysArray = Object.keys(myCurrentObj)
          let flag = false
          if (keysArray && keysArray.length) {
            keysArray.forEach((item) => {
              if (
                item % 3 === 0 &&
                !myCurrentObj.hasOwnProperty(`${Number(item) + 1}`)
              ) {
                flag = true
              } else if (
                item % 3 === 2 &&
                !myCurrentObj.hasOwnProperty(`${Number(item) - 1}`)
              ) {
                flag = true
              } else if (
                item % 3 === 1 &&
                (!myCurrentObj.hasOwnProperty(`${Number(item) - 1}`) ||
                  !myCurrentObj.hasOwnProperty(`${Number(item) + 1}`))
              ) {
                flag = true
              }
            })

            if (flag)
              return this.$confirm(
                '无法提交，每组必须填写3张图片。',
                '错误提示',
                {
                  confirmButtonText: '我知道了',
                  type: 'warning',
                }
              )
                .then(() => {})
                .catch(() => {})

            // 提交完成后 此时需要回显图片
            let flag1 = false
            // 限制出现重复图片
            keysArray.forEach((item) => {
              if (
                item % 3 === 0 &&
                myCurrentObj[item].id === myCurrentObj[Number(item) + 1].id
              ) {
                flag1 = true
              } else if (
                item % 3 === 2 &&
                myCurrentObj[item].id === myCurrentObj[Number(item) - 1].id
              ) {
                flag1 = true
              } else if (
                item % 3 === 1 &&
                (myCurrentObj[item].id === myCurrentObj[Number(item) + 1].id ||
                  myCurrentObj[item].id === myCurrentObj[Number(item) - 1].id)
              ) {
                flag1 = true
              }
            })

            if (flag1)
              return this.$confirm(
                '无法提交，创意物料同组出现重复图片。',
                '错误提示',
                {
                  confirmButtonText: '我知道了',
                  type: 'warning',
                }
              )
                .then(() => {})
                .catch(() => {})

            for (let key in this.groupList) {
              chooseImageList.push(this.groupList[key])
            }
          } else {
            chooseImageList = []
          }
        }
      } else {
        chooseImageList = JSON.parse(JSON.stringify(data))
      }

      if (this.chooseType === 'oneToOne') {
        if (this.pictureSizeMode === '3') {
          this.imageList = []
          chooseImageList.forEach((item) => {
            this.imageList.push({
              image: item,
              text: '',
            })
          })
        } else {
          chooseImageList.forEach((item) => {
            if (!this.imageList.find((img) => img.image.id === item.id)) {
              this.imageList.push({
                image: item,
                text: '',
              })
            }
          })
        }
      }

      if (this.chooseType === 'manyToMany') {
        if (this.pictureSizeMode === '3') {
          this.imageListWithMany = []
          chooseImageList.forEach((item) => {
            this.imageListWithMany.push({
              image: item,
            })
          })
        } else {
          chooseImageList.forEach((item) => {
            if (
              !this.imageListWithMany.find((img) => img.image.id === item.id)
            ) {
              this.imageListWithMany.push({
                image: item,
              })
            }
          })
        }
      }

      if (this.pictureSizeMode === '3' && this.chooseType === 'oneToOne') {
        let current = JSON.parse(JSON.stringify(this.imageList))
        for (let i = 0; i < current.length; i += 3) {
          this.d2ArrayOneToOne.push({
            array: current.slice(i, i + 3),
            text: '',
          })
        }
      }

      if (this.pictureSizeMode === '3' && this.chooseType === 'manyToMany') {
        let current = JSON.parse(JSON.stringify(this.imageListWithMany))
        for (let i = 0; i < current.length; i += 3) {
          this.d2ArrayManyToMany.push({
            array: current.slice(i, i + 3),
          })
        }
      }

      this.handleCancel()
    },
    clearAccountItem(index) {
      this.chooseAccountList.splice(index, 1)
    },
    refreshData(page = null) {
      if (page) {
        this.page = page
      }
      this.initData()
    },
    addTemplate() {
      this.resetData()
      this.isSave = false
    },
    pageChange(page) {
      this.page = page
      this.initData()
    },
    handleBatchDelete(cb) {
      const ids = this.selection.map((item) => item.id).join(',')
      delTemplateList(ids)
        .then(() => {
          this.$message.success('删除成功!')
          cb()
          this.initData()
        })
        .finally(() => {
          cb()
        })
    },
    getTotalNumber() {
      return this.baiduAccountList.reduce(
        (acc, b, c) => (acc += 'number' in b ? +b.number : 0),
        0
      )
    },
    handleSelectionChange(select) {
      this.selection = select
    },
    handleDocDialog() {
      this.showDocDialog = false
    },
    deleteItem(index) {
      this.baiduAccountList.splice(index, 1)
    },
    handleAccountMouseEnter(val, index) {
      if (this.ocpcBidType !== 'APPOINT') return
      if (!this.$refs.AccountItem[index]) {
        return this.handleAccountMouseLeave()
      }
      this.$refs.hoverItem.forEach((item, i) => {
        item.style.display = i === index ? 'block' : 'none'
      })
    },
    handleAccountMouseLeave() {
      if (this.ocpcBidType !== 'APPOINT') return
      this.$refs.hoverItem.forEach((item) => {
        item.style.display = 'none'
      })
    },
    handleInputChange(textValue, index) {
      const totalLength = calculateCharacter(textValue)
      totalLength > CHARACTER_LENGTH
        ? this.$refs.inputText[index].$el.classList.add('error-tips-line')
        : this.$refs.inputText[index].$el.classList.remove('error-tips-line')
    },
    handleChooseSuccess(selection) {
      getGroundList({
        page: 1,
        pageSize: 9999,
        platform: 2,
      }).then((res) => {
        this.landPageList = res.list
      })
      this.baiduAccountList = selection
    },
    handleEditItem(row) {
      this.isEdit = true
      this.resetData()
      getBaiduPlanList({
        page: 1,
        pageSize: 999,
        templateId: row.id,
      }).then((res) => {
        const list = res
        this.isSave = false
        this.$nextTick(() => {
          if (list.length > 0) {
            const symbolItem = list[0]

            this.params.campaign_feed_name =
              symbolItem.campaignFeedName.split(/_/)[0]
            // * 预算
            this.budType = symbolItem.budGet === 0 ? '不限' : '自定义'
            if (this.budType === '自定义') {
              this.params.bud_get = `${symbolItem.budGet}`
            }

            // * 推广日期
            let promotionDate = symbolItem.promotionDate
            if (promotionDate) {
              if (promotionDate === '不限') {
                this.params.promotionDate = {
                  type: '不限',
                  value: [],
                }
              } else {
                promotionDate = JSON.parse(promotionDate)
                this.params.promotionDate = promotionDate
              }
            }

            // *地域
            const region = symbolItem.region
            if (region.includes('不限')) {
              this.regionType = '不限'
            } else {
              this.regionType = '自定义'
              this.$nextTick(() => {
                const regionArr = region.split(',')
                this.params.region = region
                if (regionArr.includes('中国')) {
                  this.$refs.regionTree.setCheckedKeys([1])
                } else {
                  const regionIds = regionArr.map((item) => {
                    return this.regionData[0].children.find(
                      (child) => child.label === item
                    ).id
                  })
                  this.$refs.regionTree.setCheckedKeys(regionIds)
                }
              })
            }

            // * 年龄
            this.params.age = JSON.parse(symbolItem.age)

            // * 性别
            this.params.sex = symbolItem.sex

            // * 自动扩量

            this.params.auto_expansion = JSON.parse(symbolItem.autoExpansion)
            // * 推广url
            this.params.ip_url = symbolItem.ipUrl

            // * 单元名称
            this.params.adgroup_feed_name =
              symbolItem.adgroupFeedName.split(/_/)[0]

            // * 创意名称

            this.params.creative_feed_name = symbolItem.creativeFeedName

            // * 计划模式 创意物料相关
            this.projectType = symbolItem.projectType
            this.progFlag = symbolItem.progFlag === 1
            this.pictureSizeMode = String(symbolItem.materialType)
            setTimeout(() => {
              this.distributionType = symbolItem.distributionType
            }, 0)

            if (this.projectType === 'oneToOne') {
              if (this.pictureSizeMode === '3') {
                this.$nextTick(() => {
                  if (symbolItem.distributionType === 'repeat') {
                    let id = symbolItem.baiduChannelMarketingId
                    let arrays = list.filter((item) => {
                      return id === item.baiduChannelMarketingId
                    })

                    const array = arrays.map((item) => {
                      const arrayTools = item.baiDuImage.map((itemB) => {
                        return {
                          image: {
                            src: itemB.imageUrl,
                            id: itemB.materialId,
                          },
                        }
                      })

                      return {
                        array: arrayTools,
                        text: item.creativeTitle,
                      }
                    })

                    this.d2ArrayOneToOne = JSON.parse(JSON.stringify(array))
                  } else {
                    const array = list.map((item) => {
                      const arrayTools = item.baiDuImage.map((itemB) => {
                        return {
                          image: {
                            src: itemB.imageUrl,
                            id: itemB.materialId,
                          },
                        }
                      })

                      return {
                        array: arrayTools,
                        text: item.creativeTitle,
                      }
                    })

                    this.d2ArrayOneToOne = JSON.parse(JSON.stringify(array))
                  }

                  let x = 0
                  let current = {}
                  this.d2ArrayOneToOne.forEach((item) => {
                    item.array.forEach((itemA) => {
                      current[x++] = {
                        src: itemA.image.src,
                        id: itemA.image.id,
                      }
                    })
                  })

                  this.groupList = current
                })
              } else {
                this.$nextTick(() => {
                  const imgList = []
                  list.forEach((item) => {
                    if (
                      !imgList.find((img) => img.image.id === item.materialId)
                    ) {
                      imgList.push({
                        image: {
                          src: item.baiDuImage[0].imageUrl,
                          id: item.materialId,
                        },
                        text: item.creativeTitle,
                      })
                    }
                  })
                  this.imageList = imgList
                })
              }
            }
            if (this.projectType === 'manyToMany') {
              this.$nextTick(() => {
                let currentList = JSON.parse(JSON.stringify(list))
                const doc = []
                list.forEach((item) => {
                  if (!doc.find((val) => val === item.creativeTitle)) {
                    doc.push(item.creativeTitle)
                  }
                })
                this.docWithManyList = doc
                this.echoDocListWithManyToMany = JSON.parse(JSON.stringify(doc))

                if (this.pictureSizeMode === '3') {
                  if (symbolItem.distributionType === 'average') {
                    let length = list.length / this.docWithManyList.length
                    let currentArray = currentList.slice(0, length)

                    const array = currentArray.map((item) => {
                      const arrayTools = item.baiDuImage.map((itemB) => {
                        return {
                          image: {
                            src: itemB.imageUrl,
                            id: itemB.materialId,
                          },
                        }
                      })

                      return {
                        array: arrayTools,
                        text: item.creativeTitle,
                      }
                    })
                    this.d2ArrayManyToMany = JSON.parse(JSON.stringify(array))
                  } else {
                    // 全排列 先找到第一个人的
                    let id = symbolItem.baiduChannelMarketingId
                    let arrays = list.filter((item) => {
                      return id === item.baiduChannelMarketingId
                    })

                    let length = arrays.length / this.docWithManyList.length
                    let currentArray = currentList.slice(0, length)

                    const array = currentArray.map((item) => {
                      const arrayTools = item.baiDuImage.map((itemB) => {
                        return {
                          image: {
                            src: itemB.imageUrl,
                            id: itemB.materialId,
                          },
                        }
                      })

                      return {
                        array: arrayTools,
                        text: item.creativeTitle,
                      }
                    })

                    this.d2ArrayManyToMany = JSON.parse(JSON.stringify(array))
                  }

                  // 回显选择器里面的图片
                  let x = 0
                  let current = {}
                  this.d2ArrayManyToMany.forEach((item) => {
                    item.array.forEach((itemA) => {
                      current[x++] = {
                        src: itemA.image.src,
                        id: itemA.image.id,
                      }
                    })
                  })

                  this.groupList = current
                } else {
                  const imgList = []
                  list.forEach((item) => {
                    if (
                      !imgList.find((img) => img.image.id === item.materialId)
                    ) {
                      imgList.push({
                        image: {
                          src: item.baiDuImage[0].imageUrl,
                          id: item.materialId,
                        },
                      })
                    }
                  })

                  this.imageListWithMany = imgList
                }
              })
            }

            // * 模板名称
            this.templateName = row.templateName
          }
        })
      })
    },
    checkTemplateDetail(id) {
      getBaiduPlanList({
        page: 1,
        pageSize: 999,
        templateId: id,
      }).then((res) => {
        this.singleTemplateId = id
        this.singleTemplateList = res
        this.showSingleTemplateDialog = true
      })
    },
    saveLocalData() {
      localStorage.setItem('templateData', JSON.stringify(this.$data))
    },
    showMessage(message, type = 'info', cb) {
      if (typeof cb === 'function') {
        cb()
      }
      return this.$message({
        message,
        showClose: true,
        type,
      })
    },
    // 从上传图片那里回显数据
    transformData(data) {
      const chooseImageList = data.map((img) => {
        return {
          src: img.resources[0].url,
          id: img.materialId,
        }
      })
      this.handleConfirm(chooseImageList)
    },
    deleteAll(index, type) {
      type === 'oneToOne'
        ? this.d2ArrayOneToOne.splice(index, 1)
        : this.d2ArrayManyToMany.splice(index, 1)
    },
    handleRowClick(type, refName, row) {
      this.$refs[type].$refs[refName].toggleRowSelection(row)
    },
    resetData() {
      this.singleTemplateId = null
      this.unifyPrice = ''
      this.maxNum = 120
      this.isSave = true
      this.imageList = []
      this.region = '不限'
      this.budType = '不限'
      this.targetName = ''
      this.isSubmit = false
      this.accountList = []
      this.templateName = ''
      this.regionType = '不限'
      this.previewData = null
      this.preTableList = null
      this.curTableList = null
      this.subAccountList = []
      this.isRenderAuto = false
      this.chooseAccountList = []
      this.baiduAccountList = []
      this.chooseType = 'oneToOne'
      this.copySubAccountList = []
      this.projectType = 'oneToOne'
      this.manyToManyProjectList = []
      this.distributionType = 'average'
      this.params = {
        f_business: '', // 单元设置-推广业务
        consumption: '标准投放', // 消耗方式
        bud_get: '', // 预算
        promotionDate: {
          type: '不限',
          value: [],
        }, // 推广日期
        promotionPeriod: '不限',
        campaign_feed_name: '', // 名字
        f_types: '百度信息流', // 投放范围
        region: '不限',
        age: {
          text: '不限',
          startRange: '',
          endRange: '',
        },
        sex: '男', // 性别
        life_stage: '不限',
        education: '不限',
        new_interests: '不限',
        key_words: '不限', // 意图词
        crowd: '不限', // 人群包
        exclude_trans: '不限', // 排除已转化用户
        device: '不限', // 操作系统
        app: '不限', // APP行为
        network: '不限',
        auto_expansion: false,
        bid_type: '转化', // 优化目标
        pay_mode: 'oCPM', // 付费模式
        ip_url: '', // !推广URL
        adgroup_feed_name: '', // 单元名称
        creative_feed_name: '', // 创意名称
        creative_title: [
          // 文案
          {
            text: '',
          },
        ],
      }

      this.progFlag = false
      this.groupList = {} //选中的图片对象
      this.d2ArrayOneToOne = []
      this.d2ArrayManyToMany = []
      this.pictureSizeMode = '1' //选择上传图片的模式  1：单小图 2：单大图  3：固定三图
    },
  },
  beforeRouteLeave(to, from, next) {
    if (!this.isSave) {
      if (confirm('确定要继续吗？未保存的数据将会丢失!') === true) {
        next()
      } else {
        next(false)
      }
    } else {
      next()
    }
  },

  beforeDestroy() {
    this.saveLocalData()
    this.resetData()
  },
  components: {
    AddPicture,
    PreviewItem,
    PictureLibrary,
    BaiduAccountList,
    CountableTextarea,
    SingleTemplateList,
  },
}
</script>
<style lang='scss' scoped>
/deep/ .el-input__inner {
  background: inherit !important;
  color: #909399;
}
.error-tips-line {
  /deep/ .el-textarea__inner {
    border-color: #fd5d5a;
    box-shadow: 0 0 0 2px #fd5d5a;
  }
}
@mixin display-center-center {
  display: flex;
  justify-content: center;
  align-items: center;
}
/deep/ .el-drawer__body {
  padding: 0 20px;
}
/deep/.my_private_dia {
  .el-dialog__body {
    padding: 0 20px 0 20px;
  }
}
.add-template {
  .title {
    font-weight: bold;
    font-size: 20px;
  }
  .baidu-container {
    margin-top: 20px;
    padding-top: 10px;
    border-top: 1px solid #f5f5f5;
    max-height: 700px;
    overflow: auto;
    .per-part {
      h3 {
        margin: 10px 0;
      }
      .part {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        margin-bottom: 20px;
        > span {
          font-size: 13px;
          width: 100px;
          text-align: right;
          margin-right: 10px;
        }
      }
      .custom-part {
        > span {
          font-size: 13px;
          width: 100px;
          text-align: right;
          margin-right: 10px;
        }
      }
    }
  }
  .button-list {
    padding-top: 10px;
    border-top: 1px solid #f5f5f5;
  }
}
.image-list {
  width: 1200px;
  margin-top: 10px;
  > div {
    width: 250px;
    margin-bottom: 10px;
    margin-right: 20px !important;
    .image-item {
      position: relative;
      width: 250px;
      height: 250px;
      background-color: rgb(204, 204, 204);
      @include display-center-center;
      margin: 0 10px 5px 0;
      border-radius: 3px;

      img {
        width: 100%;
        height: 100%;
        margin: 0 auto;
        object-fit: contain;
      }
      .del-icon {
        cursor: pointer;
        position: absolute;
        top: 3%;
        left: 85%;
        font-size: 18px;
        color: red;
        display: none;
      }
      &:hover {
        .del-icon {
          display: block;
        }
      }
    }
    .copywriting {
      min-height: 80px;
      width: 250px;
      bottom: 0;
      line-height: 50px;
      position: relative;
      .character-tips {
        position: absolute;
        top: -12%;
        left: 91%;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        text-align: center;
        line-height: 20px;
        color: #fff;
        background: #ccc;
        transform: scale(0.75);
        font-size: 12px;
      }
    }
  }
}
.account-list {
  padding-top: 10px;
  .account-list-container {
    max-width: 1400px;
    position: relative;
    .account-item {
      margin: 10px 15px 40px 0;
      font-size: 12px;
      position: relative;
      @include flex(flex-start, center);
      transition: all 0.2s;
      .account {
        border-radius: 4px;
        cursor: pointer;
        border: 1px solid #ccc;
        padding: 4px 6px;
        position: relative;
        .delete-item {
          position: absolute;
          top: -30%;
          left: 90%;
          font-size: 14px;
          color: #fd5d5a;
          display: none;
        }
      }
      .extra-operation {
        margin-left: 20px;
      }
      .distributionType-number {
        position: absolute;
        width: 20px;
        height: 20px;
        left: -12%;
        top: -50%;
        border-radius: 50%;
        text-align: center;
        line-height: 20px;
        background: #00bf8a;
        color: #fff;
        transform: scale(0.75);
      }
      .hover-desk {
        top: -55px;
        width: 200px;
        height: 50px;
        display: none;
        z-index: 999;
        line-height: 50px;
        border-radius: 6px;
        position: absolute;
        background-color: #fff;
        > div {
          padding: 0 10px;
        }
      }
      &:hover {
        .delete-item {
          display: block;
        }
        .hover-desk {
          display: block;
        }
      }
    }
  }
}
.tips {
  color: #ccc;
  > span {
    color: #fd5d5a;
  }
}
.project-type {
  .project-type-title {
    text-align: right;
  }
  .project-type-detail {
    margin-top: 20px;
    > div {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-top: 20px;
      > span {
        font-size: 14px;
        width: 80px;
        text-align: right;
        margin-right: 10px;
      }
      .textarea {
        flex-grow: 1;
        max-width: 550px;
      }
    }
  }
}
.small-title {
  font-size: 13px;
  width: 100px;
  text-align: right;
  margin-right: 10px;
}
.region-container {
  height: 300px;
  width: 200px;
  overflow: auto;
  margin-top: 10px;
}
.custom-option-style {
  @include flex(space-between, center);
}

.picture_mode {
  margin-bottom: 10px;
}

.picture_dialog {
  width: 100%;
  height: 100%;
}

.item_row {
  position: relative;
  width: 600px;
  margin-bottom: 20px;
  display: flex;
  flex-direction: column;
  .item_row_top {
    width: 100%;
    height: 100%;
    display: flex;
    flex-wrap: wrap;
  }
}

.myCopyRight {
  margin-top: 10px;
  width: 500px !important;
  display: flex;

  /deep/textarea {
    width: 470px;
  }
}

.deleteAll {
  position: absolute;
  right: 30px;
  top: 20px;
  cursor: pointer;
  i {
    margin-right: 10px;
  }
}

.image-list-my {
  width: 1300px;
  display: flex;
  flex-wrap: wrap;
  .image-list-my-item {
    width: 600px;
  }
}

.image-item-my {
  width: 150px !important;
  height: 150px !important;
}
</style>
