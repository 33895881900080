<template>
  <el-drawer
    title="模板数据计划列表"
    direction="rtl"
    size="75%"
    append-to-body
    :visible.sync="show"
    :before-close="handleClose"
    :destroy-on-close="true"
    :show-close="true"
    :wrapperClosable="true"
  >
    <dy-table
      :dataList="dataList"
      :rowList="templateRowList"
      :page="page"
      :tagInfo="tagInfo"
      @selection-change="handleSelectionChange"
    >
      <template #operation="{ row }">
        <c-button
          class="button-small"
          type="danger"
          :autoConfirm="true"
          @confirm="
            (cb) => {
              handleDelete(row.id, cb)
            }
          "
          >删除</c-button
        >
      </template>
      <template #promotionDate="{ row }">
        <div class="line-overflow" :title="getDate(row)">
          {{ getDate(row) }}
        </div>
      </template>
    </dy-table>
  </el-drawer>
</template>

<script>
import { delProjectItem, getBaiduPlanList } from '@/api/baidu'

export default {
  props: {
    show: {
      type: Boolean,
      default: false,
    },
    data: {
      type: Array,
      default: () => {
        ;[]
      },
    },
    singleTemplateId: Number,
  },
  data() {
    return {
      page: 1,
      total: 0,
      pageSize: 15,
      dataList: [],
      selection: [],
      tagInfo: [
        {
          tagName: 'planStatus',
          tagVal: [
            {
              val: 1,
              text: '未上传',
            },
            {
              val: 2,
              text: '待上传',
            },
            {
              val: 3,
              text: '已上传',
            },
          ],
        },
      ],
      templateRowList: [
        {
          label: '账号',
          prop: 'targetName',
          colWidth: '120',
        },
        {
          label: '计划名称',
          prop: 'campaignFeedName',
          popover: true,
          width: '150',
        },
        {
          label: '单元名称',
          prop: 'adgroupFeedName',
          popover: true,
        },
        {
          label: '状态',
          prop: 'planStatus',
          tag: true,
        },
        {
          label: '图片',
          prop: 'imageUrl',
          // popover: true,
          mediaType: 'image',
          colWidth: '220',
        },
        {
          label: '文案',
          prop: 'creativeTitle',
          popover: true,
        },
        {
          label: '地域',
          prop: 'linkAddress',
          link: true,
          colWidth: 200,
        },
        {
          label: '推广出价',
          prop: 'ocpcBid',
        },
        {
          label: '推广日期',
          prop: 'promotionDate',
          slot: true,
        },
        {
          label: '创意名称',
          prop: 'creativeFeedName',
          popover: true,
        },

        {
          label: '推广URL',
          prop: 'ipUrl',
          popover: true,
          link: true,
        },
        {
          label: '操作',
          action: 'operation',
          width: '200',
        },
      ],
    }
  },
  watch: {
    show: {
      handler(newV) {
        if (newV) {
          this.initData()
        }
      },
    },
  },
  methods: {
    initData() {
      if (this.data.length > 0) {
        this.dataList = JSON.parse(JSON.stringify(this.data))
        this.dataList.forEach((item) => {
          if (item.materialType === 3) {
            // 固定三图
            item.imageUrl = item.baiDuImage.map((item) => item.imageUrl)
          } else {
            item.imageUrl = item.baiDuImage[0].imageUrl
          }
        })
      }
    },
    handleClose() {
      this.$emit('close')
    },
    pageChange(page) {
      this.page = page
      this.initData()
    },
    handleSelectionChange(select) {
      this.selection = select
    },
    async refreshData(cb) {
      try {
        await this.initData()
        cb()
      } catch (e) {
        cb()
      }
    },
    batchDelete(cb) {
      const ids = this.selection.map((item) => item.id).join(',')
      delProjectItem(ids)
        .then(() => {
          this.$message.success('删除成功!')
          this.$emit('refreshData')
        })
        .finally(() => {
          cb()
        })
    },
    getDate(row) {
      return row.promotionDate
        ? row.promotionDate === '不限'
          ? '不限'
          : JSON.parse(row.promotionDate).type === '不限'
          ? '不限'
          : `${JSON.parse(row.promotionDate).value[0]} ~ ${
              JSON.parse(row.promotionDate).value[1]
            }`
        : '不限'
    },
    refreshData() {
      getBaiduPlanList({
        page: 1,
        pageSize: 999,
        templateId: this.singleTemplateId,
      }).then((res) => {
        this.dataList = res
        this.dataList.forEach((item) => {
          if (item.materialType === 3) {
            // 固定三图
            item.imageUrl = item.baiDuImage.map((item) => item.imageUrl)
          } else {
            item.imageUrl = item.baiDuImage[0].imageUrl
          }
        })
      })
    },
    handleDelete(id, cb) {
      delProjectItem(id)
        .then(() => {
          this.$message.success('删除成功')
          // this.$emit("refreshData");
          this.refreshData()
        })
        .finally(() => {
          if (typeof cb === 'function') {
            cb()
          }
        })
    },
  },
  components: {},
}
</script>
<style lang='scss' scoped>
</style>
